import { useContext, useState, useEffect } from 'react'
import { AppContext } from '../App'
import * as c from '../c'

export default function UsersEditor(){
  const App = useContext(AppContext),
        icons = App.icons

  const [loading, setLoading] = useState(false),
        [users, setUsers] = useState([]),
        [activeUser, setActiveUser] = useState({}),
        [parceiros, setParceiros] = useState([]),
        [search, setSearch] = useState(''),
        [perfisList, setPerfisList] = useState([])

  async function init(){
    setLoading(true)
    setUsers( (await App.api('perfis::users')).results )
    setPerfisList(
      (await App.api('perfis::list')).results.map(perfil => ({
        ID: perfil.ID_UOCC,
        NAME: perfil.DS_PFL
      }))
    )
    setLoading(false)
  }

  async function userAdd(){
    setUsers([{ID_UOCC: -1}, ...users])
    setActiveUser({ID_UOCC: -1, IN_STATUS: '1', editing: true})
  }


  async function getUser(){
    if( !!activeUser.ID ){
      setActiveUser({loading: true})
      let _activeUser = (await App.api('perfis::users', activeUser)).results[0] ?? {}
      setActiveUser( _activeUser )
      setParceiros(!!_activeUser.ID_FNC
        ? [{ID: _activeUser.ID_FNC??0, NAME: _activeUser.NM_PES??''}]
        : []
      )
    }
  }

  async function userEditingCancel(){
    if( users[0].ID_UOCC === -1 ){
      users.shift()
      setUsers([...users])
      setActiveUser({})
    }else{
      setActiveUser({ID: activeUser.ID_UOCC})
    }
  }

  async function userSave(){
    setActiveUser({...activeUser, loading: true})
    const _users = await App.api('perfis::userSave', activeUser)
    if( _users.status ){
      App.toast.success('Salvo', { autoClose: 500 })
      setUsers( _users.results )
      setActiveUser({
        ID: _users.results.find(u => u.ID_LOGIN.trim() === activeUser.ID_LOGIN.trim())?.ID_UOCC
      })
    }else{
      setActiveUser({...activeUser, loading: false})
    }
  }

  useEffect(() => init(), [])
  useEffect(() => getUser(), [activeUser])

  return(
    <div className='f f-column f1' style={{height: 500}}>
      {loading && <App.LoadingComponent className="h100" />}

      {!loading &&
        <div className='f g1' style={{height: 500}}>
          <div className='f f-column g1'
            style={{
              maxHeight: '100%',
              padding: 5,
              borderRadius: 5,
              background: 'var(--background)',
              border: '1px dashed var(--color)',
            }}
          >
            {!loading && !activeUser.editing &&
              <div className='f g1 f-between center-v'>
                <button onClick={() => userAdd()}>
                  <icons.MdCreateNewFolder />Novo
                </button>

                <c.Input fit clearable placeholder={'Busca'} value={search} onChange={e => setSearch( e.target.value )} />
              </div>
            }

            <ul className='menu'
              style={{
                overflowY: activeUser.editing ? 'hidden' : 'auto',
                display: 'block',
                minWidth: 270
              }}
            >
              {users
                .filter(u => !search || u.ID_LOGIN.toUpperCase().indexOf(search.toUpperCase()) >= 0)
                .map(user =>
                  <li key={user.ID_UOCC}
                    className={[
                      activeUser.ID_UOCC === user.ID_UOCC
                        ? 'active'
                        : activeUser.editing ? 'disabled' : ''
                    ].join(' ')}
                  >
                    <span onClick={() => !activeUser.editing && setActiveUser({ID: user.ID_UOCC})}>
                      <span className='menu-name'>
                        {user.ID_LOGIN ?? '-- NOVO USUÁRIO --'}
                      </span>
                    </span>
                  </li>
                )
              }
            </ul>
          </div>

          {!!activeUser.loading
            ? <App.LoadingComponent className="h100"/>
            : !!activeUser.ID_UOCC
              ? <div className='f1 f f-column f-between g1'
                  style={{
                    padding: 5,
                    borderRadius: 5,
                    background: 'var(--background)',
                    border: '1px dashed var(--color)',
                  }}
                >
                  <div className='f f-column g1'>
                    <div style={{fontSize: 18}} className='center-h'>
                      {activeUser.ID_UOCC === -1
                        ? <>Novo usuário</>
                        : <>Editando: <b>{activeUser.ID_LOGIN}</b></>
                      }
                    </div>

                    <c.Input label={'Login'}
                      value={activeUser.ID_LOGIN??''}
                      title={'Não são permitidos caracteres acentuados ou especiais'}
                      minLength={5}
                      onChange={e => setActiveUser({...activeUser,
                        editing: true,
                        ID_LOGIN: e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
                      })}
                    />
                    <c.Input label={'Nome'}
                      value={activeUser.NM_USR??''}
                      minLength={5}
                      onChange={e => setActiveUser({...activeUser, editing: true, NM_USR: e.target.value})}
                    />
                    {activeUser.ID_UOCC === '1'
                      ? <c.Span label={'Status'}
                          value={activeUser.IN_STATUS === '1' ? 'Ativo' : 'Bloqueado'}
                        />
                      : <c.Select label={'Status'}
                          value={activeUser.IN_STATUS}
                          onChange={e => setActiveUser({...activeUser, editing: true, IN_STATUS: e.value})}
                          options={[
                            {value: '-3', label: 'Bloqueado'},
                            {value: '1', label: 'Ativo'},
                          ]}
                        />
                    }
                    <c.Select label={'Parceiro associado'} searchable clearable
                      value={activeUser.ID_FNC} options={parceiros}
                      onInputChange={(s, {action}) => {
                        if( action === 'input-change')
                          App.api('perfis::funcionarios',{s})
                            .then(r => setParceiros(r.results))
                        return s
                      }}
                      onChange={e => setActiveUser({...activeUser, editing: true, ID_FNC: e?.value})}
                    />
                    <c.Select label={'Perfil associado'} searchable clearable
                      value={activeUser.PFL_ID} options={perfisList}
                      onChange={e => setActiveUser({...activeUser, editing: true, PFL_ID: e?.value})}
                    />
                  </div>

                  {activeUser.editing &&
                    <div className='f g2'>
                      <button className='success'
                        onClick={() => userSave()}
                        disabled={!(
                          activeUser.ID_LOGIN?.length >= 5 &&
                          activeUser.NM_USR?.length >= 5
                        )}
                      ><icons.MdSave />Salvar</button>

                      <button className='danger'
                        onClick={() => userEditingCancel()}
                      ><icons.MdCancel />Cancelar</button>
                    </div>
                  }
                </div>
              : <div className='bold f center center-margin f-column g3 w100'>
                  <icons.FaUserTie size={60} />
                  <h2>Selecione um usuário</h2>
                </div>
          }
        </div>
      }
    </div>
  )
}
