import React, { useCallback, useContext, useMemo, useState } from 'react'
import { BsThreeDotsVertical as DropdownIcon } from 'react-icons/bs'
import {
  MdAttachFile as AttachIcon,
  MdThumbDown as DisapproveIcon,
  MdThumbUp as ApproveIcon,
  MdRemoveRedEye as AnalysisIcon,
  MdNotificationsActive as NotifyIcon,
  MdHourglassTop as QuarantineIcon
} from 'react-icons/md'

import { IoMdChatboxes as ChatIcon } from 'react-icons/io'
import { HiDocumentArrowUp as IncludeIcon } from "react-icons/hi2";
import { FaCoins as InvoiceIcon } from "react-icons/fa";

import { AppContext } from '../../../../App';
import { DropdownMenu, IconButton } from '../../../../c'

import { InvoicingModal } from '../modals/InvoicingModal';
import { saveAudit } from '../useCases/saveAudit';
import DfeModal from '../../../dfeModal';
import NfpModal from '../../../nfpModal';
import AnexarTicketModal from '../../../anexarTicketModal'
import AnexarDcoAuxModal from '../../../anexarDcoAuxModal';
import { EventosModal } from '../../../eventosModal';
import AnexarCanhotoModal from '../../../anexarCanhotoModal';

export const ActionColumn = ({ columns, row, user, onFetchRecords }) => {
  const { api, confirm } = useContext(AppContext)
  const App = useContext(AppContext);
  const { record } = row
  const [openInvoicingModal, setOpenInvoicingModal] = useState(false)
  const [openNfeModal, setOpenNfeModal] = useState(false)
  const [openNfpModal, setOpenNfpModal] = useState(false)
  const [openCanhotoModal, setOpenCanhotoModal] = useState(false)
  const [openCteModal, setOpenCteModal] = useState(false)
  const [openMdfeModal, setOpenMdfeModal] = useState(false)
  const [openTicketModal, setOpenTicketModal] = useState(false)
  const [openDcoAuxModal, setOpenDcoAuxModal] = useState(false)
  const [openEventosModal, setOpenEventosModal] = useState(false)

  const sendForAnalysis = useCallback(async () => {
    await App.api("controllership::to_analyze", { ID_UOCC: record.ticketId });

    onFetchRecords();
  }, [App, onFetchRecords, record.ticketId]);

  const itens = useMemo(
    () => [
      {
        title: 'Incluir documento',
        icon: <AttachIcon />,
        disabled: !record.allowsToUpload,
        action: () => null,
        itens: [
          {
            title: 'Ticket Descarga',
            disabled: false,
            action: () => setOpenTicketModal(true)
          },
          {
            title: 'NF-e',
            disabled: false,
            action: () => setOpenNfeModal(true)
          },
          {
            title: 'CT-e',
            disabled: false,
            action: () => setOpenCteModal(true)
          },
          {
            title: 'MDF-e',
            disabled: false,
            action: () => setOpenMdfeModal(true)
          },
          {
            title: 'NF-P',
            disabled: false,
            action: () => setOpenNfpModal(true)
          },
          {
            title: 'Canhoto',
            disabled: false,
            action: () => setOpenCanhotoModal(true)
          },
          {
            title: 'Documento Auxiliar',
            disabled: false,
            action: () => setOpenDcoAuxModal(true)
          },
        ]
      },
      record.toAnalyze && {
        title: 'Analisar',
        icon: <AnalysisIcon />,
        disabled: !record.allowAnalysis,
        action: () => sendForAnalysis(),
      },
      {
        title: 'Incluir requisição',
        icon: <IncludeIcon />,
        disabled: true,
        action: () => null,
      },
      {
        title: 'Notificar',
        icon: <NotifyIcon />,
        disabled: true,
        action: () => null,
      },
      {
        title: 'Quarentena',
        icon: <QuarantineIcon />,
        disabled: true,
        action: () => null,
      },
      {
        title: 'Eventos',
        icon: <ChatIcon />,
        disabled: false,
        action: () => {setOpenEventosModal(true)},
      }
    ],
    [record.allowAnalysis, record.allowsToUpload, record.toAnalyze, sendForAnalysis]
  )

  const handleClickInvoicing = useCallback((opened) => {
    return () => setOpenInvoicingModal(opened)
  }, [])

  const buttons = useMemo(() => {
    const actions = []

    actions.push([
      'eventos',
      () => (
        <IconButton
          onClick={() => {setOpenEventosModal(true)}}
        >
          <ChatIcon  size={24} />
        </IconButton>
      )
    ])

    if (record.approvable) {
      actions.push([
        'approve',
        () => (
          <IconButton
            disabled={!record.allowsToApprove}
            onClick={() => {
              confirm(
                `Deseja aprovar a viagem do ticket ${record.ticketId}`,
                async () => {
                  const status = await saveAudit({ api, records: [record], action: 'approve' })
                  onFetchRecords()
                  return status
                }
              )
            }}
          >
            <ApproveIcon className="success" size={24} />
          </IconButton>
        )
      ])
    }

    if (record.disapprovable) {
      actions.push([
        'disapprove',
        () => (
          <IconButton
            disabled={!record.allowsToDisapprove}
            onClick={() => {
              confirm(
                `Deseja reprovar a viagem do ticket ${record.ticketId}`,
                async () => {
                  const status = await saveAudit({ api, records: [record], action: 'disapprove' })
                  onFetchRecords()
                  return status
                }
              )
            }}
          >
            <DisapproveIcon className="danger" size={24} />
          </IconButton>
        )
      ])
    }

    if (record.billable) {
      actions.push([
        'invoice',
        () => (
          <IconButton
            disabled={!record.allowsToInvoice}
            onClick={handleClickInvoicing(true)}
          >
            <InvoiceIcon className="info" size={24} />
          </IconButton>
        )
      ])
    }

    return actions
  }, [api, confirm, handleClickInvoicing, onFetchRecords, record])

  return (
    <div className='f f-nwrap' >
      {buttons.map(([key, Button]) => (<Button key={key} />))}

      <DropdownMenu itens={itens} direction='left'>
        <IconButton icon={DropdownIcon} size={24} />
      </DropdownMenu>

      {openInvoicingModal && (
        <InvoicingModal
          visible={openInvoicingModal}
          columns={columns}
          rows={[row]}
          onClose={handleClickInvoicing(false)}
          onFinish={onFetchRecords}
        />
      )}

      {!!openNfeModal && (
        <DfeModal
          mod={55}
          onClose={() => { setOpenNfeModal(false) }}
          onFinish={onFetchRecords}
          portaria={{
            ...record,
            ID_VCL: record.vehicle,
            ID_UOCC: record.ticketId,
            ID_CN: record.contractId,
            LST_VCL: record.trailers,
            ID_NCM: record.productNcm,
            NM_PRO: record.productGroup
          }}
          tela="agendado"
        />
      )}

      {!!openNfpModal && (
        <NfpModal
          onClose={() => setOpenNfpModal(false)}
          onFinish={onFetchRecords}
          portaria={record}
        />
      )}

      {!!openCteModal && (
        <DfeModal
          mod={57}
          onClose={() => { setOpenCteModal(false) }}
          onFinish={onFetchRecords}
          portaria={
            {
              ...record,
              ID_UOCC: record.ticketId,
              ID_CN: record.contractId,
              ID_VCL: record.vehicle
            }}
          tela="agendado"
        />
      )}

      {!!openMdfeModal && (
        <DfeModal
          mod={58}
          onClose={() => { setOpenMdfeModal(false) }}
          onFinish={onFetchRecords}
          portaria={record}
          tela="agendado"
        />
      )}

      {!!openTicketModal && (
        <AnexarTicketModal
          cota={record}
          onFinish={onFetchRecords}
          onClose={() => { setOpenTicketModal(false) }}
        />
      )}

      {!!openCanhotoModal && (
        <AnexarCanhotoModal
        cota = {record}
        onFinish = { onFetchRecords }
        onClose = {() => { setOpenCanhotoModal(false) }}
        />
      )}

      {!!openDcoAuxModal && (
        <AnexarDcoAuxModal
          onClose={() => setOpenDcoAuxModal(false)}
          onFinish={onFetchRecords}
          portaria={record}
        />
      )}

      {!!openEventosModal && (
        <EventosModal
          onClose={() => setOpenEventosModal(false)}
          onFinish={onFetchRecords}
          portaria={record}
        />
      )}
    </div>
  )
}
