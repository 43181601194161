import { Fragment, useContext } from "react"

import { AppContext } from "../../App"
import { CSVDownload } from "../CSVDownload"
import { IconButton } from "../IconButton"

export function Frame({
  actions = null,
  autoScroll = false,
  children,
  className = '',
  control = null,
  controls = [],
  flex = null,
  loading = false,
  noPadding = null,
  title,
  ...rest
}){
  const { icons, lang, LoadingComponent } = useContext(AppContext)

  return(
    <div className={'frame f f-column '+(noPadding?' noPadding ':'')+className} {...rest}>
      {(!!title || !!actions || !!control) &&
        <div className='frame-titleBar'>
          {!!title && <h3>{title}</h3>}
          {!!control && <div className='frame-title-control'>{control}</div>}

          {controls.length > 0 && (
            <div className='frame-title-control'>
              {controls.map(([key, currentControl]) => (
                <Fragment key={key}>{currentControl}</Fragment>
              ))}
            </div>
          )}
          {!!actions && (
            <div className='frame-actions'>
              {!!actions.add    && <IconButton className='success' onClick={actions.add} ><icons.MdAdd size={24} title={lang.global.adicionar} /></IconButton>}
              {!!actions.edit   && <IconButton onClick={actions.edit}  ><icons.MdEdit size={24} title={lang.global.editar} /></IconButton>}
              {!!actions.delete && <IconButton onClick={actions.delete}><icons.MdDelete size={24} title={lang.global.remover} /></IconButton>}
              {!!actions.filter && <IconButton onClick={actions.filter.onClick} className={actions.filter.value?'selected':''}><icons.MdFilterAlt size={24} title={lang.global.filter} /></IconButton>}
              {!!actions.toggleCardsList &&
                <IconButton onClick={actions.toggleCardsList.onClick}>{
                  actions.toggleCardsList.value
                    ? <icons.MdViewModule size={24} title={lang.global.ver_como_cards} />
                    : <icons.MdList size={24} title={lang.global.ver_como_table} />
                  }</IconButton>}
              {!!actions.upload && <IconButton onClick={actions.upload}><icons.MdUpload size={24} title={'Upload'} /></IconButton>}
              {!!actions.CSVDownload &&
                  (actions.CSVDownload.enabled === undefined || actions.CSVDownload.enabled)
                ? (
                  <IconButton title={'Download CSV'}>
                    <CSVDownload {...actions.CSVDownload}>
                      <icons.MdDownload size={24} />
                    </CSVDownload>
                  </IconButton>
                ) : (
                  <IconButton disabled title={'Download CSV'}>
                    <icons.MdDownload size={24} />
                  </IconButton>
              )}
              {!!actions.approve && (
                <button
                  className='success'
                  disabled={!actions.approve.enabled}
                  onClick={actions.approve.onClick}
                  >
                  <icons.MdThumbUp size={24}/> Aprovar
                </button>
              )}
              {!!actions.disapprove && (
                <button
                  className='danger'
                  disabled={!actions.disapprove.enabled}
                  onClick={actions.disapprove.onClick}
                >
                  <icons.MdThumbDown size={24}/> Reprovar
                </button>
              )}
              {!!actions.invoice && (
                <button
                  className='info'
                  disabled={!actions.invoice.enabled}
                  onClick={actions.invoice.onClick}
                >
                  <icons.FaCoins size={24}/> Faturar
                </button>
              )}
            </div>
          )}
        </div>
      }
      <div className='frame-content' style={{overflowX: autoScroll?'auto':'inherit', display: flex?'flex':null}}>
        {loading
          ? <LoadingComponent />
          :children}
      </div>
    </div>
  )
}
