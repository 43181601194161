import { useContext } from 'react';
import { AppContext } from '../../../App';
import './styles.sass';
import FormattedParagraph from './FormatContent/FormattedParagraph/FormattedParagraph';


const ModalContent = ({ documentType, lang }) => {
  const introduction = lang.introduction.replace(
    '{via_name}',
    '<strong class="custom-strong">VIA</strong>',
  )

  const { clear_image_name, complete_information_name, proper_lighting_name, full_capture_name, neutral_background_name  } = lang
  const clearImage = lang.clear_image.replace('{clear_image_name}',`<strong class="custom-strong">${clear_image_name}</strong>`)
  const completeInformation = lang.complete_information.replace('{complete_information_name}',`<strong class="custom-strong">${complete_information_name}</strong>`)
  const properLighting = lang.proper_lighting.replace('{proper_lighting_name}',`<strong class="custom-strong">${proper_lighting_name}</strong>`)

  const fullCaptureName = lang.full_capture.replace('{full_capture_name}',`<strong class="custom-strong">${full_capture_name}</strong>`)
  const neutralBackgroundName = lang.neutral_background.replace('{neutral_background_name}',`<strong class="custom-strong">${neutral_background_name}</strong>`)

  return (
    <div className='text'>
      <FormattedParagraph text={lang.greeting} documentType={documentType} />
      <FormattedParagraph text={introduction} documentType={documentType} />
      <FormattedParagraph text={lang.tipsTitle} documentType={documentType} />
      <ul>
        <li>
          <FormattedParagraph text={clearImage} documentType={documentType} />
        </li>
        <li>
          <FormattedParagraph text={completeInformation} documentType={documentType} />
        </li>
        <li>
          <FormattedParagraph text={properLighting} documentType={documentType} />
        </li>
        <li>
          <FormattedParagraph text={fullCaptureName} documentType={documentType} />
        </li>
        <li>
          <FormattedParagraph text={neutralBackgroundName} documentType={documentType} />
        </li>
      </ul>
      <FormattedParagraph text={lang.closing} documentType={documentType} />
    </div>
  );
};

const ModalContentContainer = ({ documentType }) => {
  const App = useContext(AppContext);
  const lang = { ...App.lang.content_modal_via };
  const icons = App.icons;

  return <ModalContent documentType={documentType} lang={lang} icons={icons} />;
};

export const generateModalContent = (documentType) => ({
  title: `Dicas da V.IA para Capturar o ${documentType} Perfeito e Agilizar Seu Frete!`,
  content: <ModalContentContainer documentType={documentType} />
});
